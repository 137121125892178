import React from "react"
// import styled from "styled-components"
// import { Link } from "gatsby"

import SEO from "../components/seo"

const NotFoundPage = () => (
  <div>
    <SEO title="oops" />
    oops. go back.
  </div>
)

export default NotFoundPage
